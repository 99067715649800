<template lang="pug">
    div.app-container
        //- 查询操作
        div.filter-container
            el-button(v-permission="['order:amazon-order:import']" type="primary" icon="el-icon-upload" @click="onImportOrderClick").filter-item.ml-5 导入订单
            el-button(v-permission="['order:amazon-order:import-address']" type="primary" icon="el-icon-upload" @click="onImportAddressClick").filter-item.ml-5 完善地址
            el-select(v-model="listQuery.store_name" placeholder="选择店铺" clearable).filter-item.ml-5
              el-option( v-for="item in storeOptions" :key="item.value" :label="item.label" :value="item.value")
            el-input(v-model="listQuery.keyword" clearable style="width: 200px;" placeholder="订单号/ASIN").filter-item.ml-5
            el-date-picker(v-model="listQuery.date" type="daterange"  value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期").filter-item.ml-5
            el-button(v-permission="['order:amazon-order:list']" type="primary" icon="el-icon-search" @click="onFilter").filter-item.ml-5 查找


        //- 查询结果 
        el-table(v-loading="listLoading" :data="list" size="small" element-loading-text="正在查询中。。。" border fit highlight-current-row)
            el-table-column(align="left" label="ASIN" prop="asin" sortable width="120px")
            el-table-column(align="left" label="SKU" prop="sku" sortable width="120px")
            el-table-column(align="left" label="店铺名称" prop="store_name" sortable width="120px")
            el-table-column(align="left" label="Amazon订单ID" prop="order_id" sortable width="180px")
              template(slot-scope="scope")
                el-link(@click="onViewAmazonOrder(scope.row)") {{scope.row.order_id}}
            el-table-column(align="right" label="购买时间" prop="shipment_date" sortable width="140px")
                template(slot-scope="scope")
                    span {{scope.row.shipment_date | formatDateAndTime}}
            el-table-column(align="right" label="购买价格" prop="item_price_per_unit" sortable width="100px" :sort-method="sortPriceFn")
                template(slot-scope="scope")
                    span {{scope.row.item_price_per_unit | numberFormatter}}
            el-table-column(align="right" label="姓名" prop="name" sortable)
            el-table-column(align="right" label="邮箱" prop="email" sortable)
            el-table-column(align="right" label="备注" prop="remark" sortable)

            el-table-column(align="center" label="操作").small-padding.fixed-width
                template(slot-scope="scope")
                    el-link(v-permission="['order:amazon-order:edit']" type="primary" size="mini" @click="onUpdate(scope.row)") 编辑
                    el-link.opt-link(v-permission="['order:amazon-order:delete']" type="primary" size="mini" @click="onDelete(scope.row)") 删除

        //- 分页
        pagination(v-show="total>0" :total="total"  :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList")

        //- 弹出层 - 批量上传订单
        el-dialog(:visible.sync="uploadForm.dialogUploadAmazonOrder" title="导入订单" :close-on-click-modal="false")
          el-upload(ref="uploadAmazonOrder" accept=".xlsx,.xls,.csv" :on-success="onUploadAmazonOrderFileSuccess" :headers="uploadForm.headers" :data="{store_name:uploadForm.store_name}" :on-change="onUploadFormChange" :auto-upload="false" :action="uploadForm.uploadAmazonOrderUrl" :file-list="uploadForm.filelist")
            el-button(slot="trigger" type="primary") 选取文件
            div(slot="tip")
              p(:class="{red:!uploadForm.store_name}") 请选择店铺
              el-select(v-model="uploadForm.store_name" placeholder="店铺名" )
                  el-option( v-for="item in storeOptions" :key="item.value" :label="item.label" :value="item.value")
              p 请直接完整上传亚马逊后台下载的订单报表
              p 重复上传的信息将覆盖之前同一个 订单ID+ASIN 的内容
              
          div(slot="footer").dialog-footer
              p(v-if="uploadForm.rtn") 本次上传: 总记录: {{uploadForm.rtn.totalCnt}} 新增记录: {{uploadForm.rtn.addedCnt}} 更新记录: {{uploadForm.rtn.updateCnt}} 
              el-button(:disabled="!uploadForm.store_name || !!uploadForm.rtn || !uploadForm.hasFile" v-permission="['order:amazon-order:import']" :loading="uploadForm.loading" @click="onUploadAmazonOrderFile" type="primary") 确认导入

        //- 弹出层 - 上传批量修改地址文件
        el-dialog(:visible.sync="uploadForm.dialogUploadAmazonAddress" title="导入订单" :close-on-click-modal="false")
          el-upload(ref="uploadAmazonAddress" accept=".xlsx,.xls,.csv" :on-success="onUploadAmazonOrderFileSuccess" :headers="uploadForm.headers" :on-change="onUploadFormChange" :auto-upload="false" :action="uploadForm.uploadAmazonAddressUrl" :file-list="uploadForm.filelist")
            el-button(slot="trigger" type="primary") 选取文件
            div(slot="tip")
              p 只可以完善已上传的亚马逊订单，不可新增
              p 必须按照模板上传，excel文件中，要有"amazon-order-id","address" 两列
              a(:href="blank_excel_url" target="blank") 下载上传的空模版文件 
          div(slot="footer").dialog-footer
              p(v-if="uploadForm.rtn") 本次上传: 总记录: {{uploadForm.rtn.totalCnt}} 新增记录: {{uploadForm.rtn.addedCnt}} 更新记录: {{uploadForm.rtn.updateCnt}} 
              el-button(:disabled="!!uploadForm.rtn || !uploadForm.hasFile" v-permission="['order:amazon-order:import-address']" :loading="uploadForm.loading" @click="onUploadAmazonAddressFile" type="primary") 确认导入


        //- 弹出层 - 修改订单
        el-dialog(:visible.sync="dialogForm" title="Amazon订单信息" :close-on-click-modal="false").pupup-form
          el-form(:inline="true" :model="form" label-width="130px" ref="form" :rules="formRules" )
            el-form-item(label="订单号")
              el-input(v-model="form.order_id" readonly)
            el-form-item(label="店铺名称")
              el-input(v-model="form.store_name" readonly)
            el-form-item(label="ASIN")
              el-input(v-model="form.asin" readonly)
            el-form-item(label="邮箱" prop="email")
              el-input(v-model="form.email")
            el-form-item(label="SKU")
              el-input(v-model="form.sku" readonly)
            el-form-item(label="姓名")
              el-input(v-model="form.name")
            el-form-item(label="购买价格" prop="item_price_per_unit")
              el-input(v-model="form.item_price_per_unit" readonly)
                template(slot="prepend") $
            el-form-item(label="地址")
              el-input(type="textarea" v-model="form.address")
            el-form-item(label="备注")
              el-input(type="textarea" v-model="form.remark")
            el-form-item(label="电话")
              el-input(v-model="form.phone")
          div(slot="footer").dialog-footer
            el-button(@click="onUpdateCancel") 取消
            el-button(v-permission="['order:amazon-order:edit']" :loading="form.loading" @click="onUpdateSave" type="primary") 保存

        //- 弹出层
        view-amazon-order(:odr_id.sync="current_odr_id")
</template>

<script>
import * as order from "../../api/order";
import { getStoreOptions } from "../../api/store";

import ViewAmazonOrder from "./components/view-amazon-order";
import Pagination from "@/components/pagination"; // Secondary package based on el-pagination

export default {
  name: "amazon-order-list",
  components: { Pagination, ViewAmazonOrder },
  data() {
    return {
      blank_excel_url:
        "https://fg.fung.cool:3443/uploads/1a0ca4298908f12920d048bbab6e5167.xlsx",
      list: null,
      total: 0,
      listLoading: true,
      storeOptions: [],
      listQuery: {
        page: 1,
        limit: 20,
        keyword: undefined,
        date: undefined,
        sort: "creation_date",
        order: "desc"
      },
      uploadForm: {
        loading: false,
        rtn: null,
        hasFile: false,
        headers: order.getExtraHeaders(),
        uploadAmazonOrderUrl: order.getUploadUrl("amazon-order-file"),
        uploadAmazonAddressUrl: order.getUploadUrl("amazon-address-file"),
        filelist: [],
        dialogUploadAmazonOrder: false,
        dialogUploadAmazonAddress: false
      },
      dialogForm: false,
      form: {},
      formRules: {
        email: {
          type: "email",
          message: "输入正确的邮箱",
          trigger: "change"
        },
        item_price_per_unit: [
          {
            required: true,
            pattern: /^\d*(\.\d{1,2})?$/,
            message: "请输入两位小数的数字",
            trigger: "change"
          }
        ]
      },
      current_odr_id: 0
    };
  },
  created() {
    this.getStoreOptions();
    this.getList();
  },
  methods: {
    sortPriceFn(a, b) {
      return (
        parseFloat(a.item_price_per_unit) - parseFloat(b.item_price_per_unit)
      );
    },
    async getStoreOptions() {
      let resp = await getStoreOptions();
      this.storeOptions = resp.data.data;
    },
    async getList() {
      this.listLoading = true;
      try {
        const resp = await order.getAmazonOrderList(this.listQuery);
        this.list = resp.data.data.items;
        this.total = resp.data.data.total;
        this.listLoading = false;
      } catch (error) {
        this.listLoading = false;
      }
    },
    onViewAmazonOrder(row) {
      this.current_odr_id = row.odr_id;
    },
    async onUpdate(row) {
      const res = await order.getAmazonOrderByPK({ odr_id: row.odr_id });
      this.form = res.data.data;
      this.dialogForm = true;
    },
    async onUpdateCancel() {
      this.dialogForm = false;
    },
    async onUpdateSave() {
      try {
        await this.$refs.form.validate();
        const res = await order.updateAmazonOrderByPK(this.form);
        if (res.data.errno == 200) {
          // 提示操作成功
          this.$notify.success({
            title: "成功",
            message: `订单:${this.form.order_id} 信息修改成功`
          });
          this.dialogForm = false;
          this.getList();
        }
      } catch (error) {
        return false;
      }
    },
    async onDelete(row) {
      try {
        // 询问是否进行
        await this.$confirm(
          "此操作将删除订单:" + row.order_id + ", 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        );

        // 执行操作
        const res = await order.deleteAmazonOrderByPK(row);

        if (res.data.errno == 200) {
          // 提示操作成功
          this.$notify.success({
            title: "成功",
            message: `订单:${row.order_id} 信息删除成功`
          });
        }
        this.getList();
      } catch (e) {
        return false;
      }
    },
    onFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    onUploadFormChange() {
      this.uploadForm.hasFile = true;
    },
    onImportOrderClick() {
      this.uploadForm.dialogUploadAmazonOrder = true;
      this.uploadForm.rtn = null;
      this.uploadForm.filelist = [];
      this.uploadForm.hasFile = false;
    },
    onUploadAmazonOrderFile() {
      this.uploadForm.loading = true;
      this.$refs.uploadAmazonOrder.submit();
    },
    onUploadAmazonOrderFileSuccess(res) {
      this.uploadForm.loading = false;
      this.uploadForm.rtn = res.data;
      this.getList();
    },
    onImportAddressClick() {
      this.uploadForm.dialogUploadAmazonAddress = true;
      this.uploadForm.rtn = null;
      this.uploadForm.filelist = [];
      this.uploadForm.hasFile = false;
    },
    onUploadAmazonAddressFile() {
      this.uploadForm.loading = true;
      this.$refs.uploadAmazonAddress.submit();
    }
  }
};
</script>
